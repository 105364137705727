import { createSlice } from '@reduxjs/toolkit';

export const utilsSlice = createSlice({
  name: 'utils',
  initialState: {
    modal: {},
    modalData: {},
    notifications: [],
    unseenNotificationsCount: 0,
    agencyOffersCount: { offers: 0, invites: 0 },
    footerVisible: true,
    networks: [],
    shouldRefresh: {},
    roleFilter: '',
    webNotifications: false,
    step: 1,
    mapsLoaded: false,
    fromDiscover: false,
  },

  reducers: {
    openModal: (state, action) => {
      const { name, data } = action.payload;

      state.modal = { ...state.modal, [name]: true };

      if (data) {
        state.modalData = { ...state.modalData, [name]: { ...state.modalData[name], ...data } };
      }
    },

    closeModal: (state, action) => {
      state.modal = { ...state.modal, [action.payload]: false };
      state.modalData = { ...state.modalData, [action.payload]: {} };
    },

    setFromDiscover: (state, action) => {
      state.fromDiscover = action.payload;
    },

    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },

    setUnseenNotificationsCount: (state, action) => {
      state.unseenNotificationsCount = action.payload;
    },

    setAgencyOffersCount: (state, action) => {
      state.agencyOffersCount = action.payload;
    },

    incrementUnseenNotificationsCount: (state, action) => {
      state.unseenNotificationsCount += action.payload;
    },

    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
    },

    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        notification => notification.id !== action.payload
      );
    },

    setNetworks: (state, action) => {
      state.networks = action.payload;
    },

    setFooter: (state, action) => {
      state.footerVisible = action.payload;
    },

    setShouldRefresh: (state, action) => {
      state.shouldRefresh = action.payload;
    },

    setRoleFilter: (state, action) => {
      state.roleFilter = action.payload;
    },

    setFromDiscover: (state, action) => {
      state.fromDiscover = action.payload;
    },

    setWebNotifications: (state, action) => {
      state.webNotifications = action.payload;
    },

    setStep: (state, action) => {
      state.step = action.payload;
    },

    setMapsLoaded: (state, action) => {
      state.mapsLoaded = action.payload;
    },
  },
});

export const {
  openModal,
  closeModal,
  addNotification,
  removeNotification,
  setNotifications,
  setUnseenNotificationsCount,
  setAgencyOffersCount,
  incrementUnseenNotificationsCount,
  setFooter,
  setNetworks,
  setShouldRefresh,
  setRoleFilter,
  webNotifications,
  setWebNotifications,
  setStep,
  setMapsLoaded,
  setFromDiscover,
} = utilsSlice.actions;

export default utilsSlice.reducer;
