import React from 'react';
import Script from 'next/script';

const PaymentScripts = () => (
  <>
    <Script
      id="collectJS"
      strategy="afterInteractive"
      src="https://secure.networkmerchants.com/token/Collect.js"
      data-tokenization-key={process.env.NEXT_PUBLIC_COLLECT_JS_TOKEN}
      data-variant="inline"
      data-field-ccnumber-placeholder="1234 1234 1234 1234"
      data-field-ccexp-placeholder="10 / 25"
      data-field-cvv-placeholder="123"
      data-custom-css='{
          "border-radius" : "20px",
          "height" : "40px",
          "padding" : "20px"
        }'
    />
    {process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ROLLBAR_ENV === 'production' && process.env.NEXT_PUBLIC_MAXMIND_ACCOUNT_ID && (
      <Script
        id="maxmind-device-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function() {
            var mmapiws = window.__mmapiws = window.__mmapiws || {};
            mmapiws.accountId = '${process.env.NEXT_PUBLIC_MAXMIND_ACCOUNT_ID}';
            var loadDeviceJs = function() {
              var element = document.createElement('script');
              element.async = true;
              element.src = 'https://device.maxmind.com/js/device.js';
              document.body.appendChild(element);
            };
            if (window.addEventListener) {
              window.addEventListener('load', loadDeviceJs, false);
            } else if (window.attachEvent) {
              window.attachEvent('onload', loadDeviceJs);
            }
          })();
        `,
        }}
      />
    )}

    {/* <Script
        strategy="afterInteractive"
        src={`https://www.paypal.com/sdk/js?client-id=${process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID}&currency=USD&intent=authorize`}
      /> */}
  </>
);

export default PaymentScripts;
