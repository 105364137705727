import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, TextareaAutosize, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Field } from 'formik';
import clsx from 'clsx';

import IOSSwitch from 'components/IOSSwitch';
import styles from 'styles/components/gigSetup/GigItemDialog.module.scss';
import useIsBuyer from 'hooks/useIsBuyer';
import useCurrentUser from 'hooks/useCurrentUser';

const margin = { marginTop: 30 };
const noMargin = { marginTop: 0 };

const AdditionalOptions = ({
  space,
  checked,
  caption,
  url,
  setFieldValue,
  errors,
  handleChange,
}) => {
  const isGG = space.name === 'Guaranteed Gains';
  const isBuyer = useIsBuyer();
  const currentUser = useCurrentUser();
  const enabledC4Creator = currentUser?.c4_creator_id && currentUser?.c4_creator_enabled && isBuyer;

  return (
    <>
      {space.has_caption && (
        <>
          <div className={styles.switchContainer} style={space.has_views ? noMargin : margin}>
            <InputLabel>
              {space.caption_name}{' '}
              {space.caption_name === 'Text' && <span className="fw-n">(required)</span>}
            </InputLabel>

            <IOSSwitch
              checked={space.caption_name === 'Text' ? true : checked.caption}
              onChange={e => handleChange(e, setFieldValue)}
              name="caption"
            />
          </div>

          {(checked.caption || space.caption_name === 'Text') && (
            <TextareaAutosize
              name="caption"
              value={caption}
              onChange={e => setFieldValue('caption', e.target.value)}
              minRows={4}
              maxRows={4}
              placeholder="Caption"
              className={clsx({
                ['error-border']:
                  (!caption && checked.caption) || (space.caption_name === 'Text' && !caption),
              })}
            />
          )}

          {((!caption && checked.caption) || (space.caption_name === 'Text' && !caption)) && (
            <span className="error-msg" style={{ marginTop: -5 }}>
              is required.
            </span>
          )}
        </>
      )}

      {space.has_url && (
        <>
          {isGG && enabledC4Creator && (
            <Alert severity="info" style={{ margin: '10px 0 20px' }}>
              Your link will be automatically generated by our system, once your purchase is
              confirmed.
            </Alert>
          )}

          <div className={styles.switchContainer}>
            <InputLabel>
              Link to a URL <span className="fw-n">{isGG ? '(optional)' : '(required)'} </span>
            </InputLabel>

            <IOSSwitch
              checked={isGG ? checked.url : true}
              onChange={e => handleChange(e, setFieldValue)}
              name="url"
            />
          </div>

          {((isGG && checked.url) || !isGG) && (
            <>
              <Field
                value={url}
                component={TextField}
                onChange={e => setFieldValue('url', e.target.value)}
                name="url"
                type="text"
                placeholder="Add url"
                variant="outlined"
                color="primary"
                error={errors.url || !url ? true : false}
              />

              {errors.url && <span className="error-msg">{errors.url}</span>}

              {!url && <span className="error-msg">is required.</span>}
            </>
          )}
        </>
      )}
    </>
  );
};

AdditionalOptions.propTypes = {
  space: PropTypes.object.isRequired,
  checked: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  caption: PropTypes.string,
  url: PropTypes.string,
};

AdditionalOptions.defaultProps = {
  caption: '',
  url: '',
  errors: {},
};

export default AdditionalOptions;
