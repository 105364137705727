import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { getRequest } from 'utils/api';
import { closeModal } from 'features/utilsSlice';
import CloseIcon from 'public/icons/close-white.svg';
import Stepper from 'components/Stepper';
import BottomButtons from 'components/BottomButtons';
import styles from 'styles/components/influencerSetup/TraitSelection.module.scss';

const TraitSelection = ({
  title,
  subtitle,
  endpoint,
  handleStepChange,
  defaultSelectedIds = [],
  noSteps,
  isLoading,
  disableButton,
  showSteps,
  isDialog,
  showButtons,
}) => {
  const [traitCategories, setTraitCategories] = useState([]);
  const [selectedTraits, setSelectedTraits] = useState({});
  const selectedTraitsArray = Object.values(selectedTraits).flat();
  const traitIds = selectedTraitsArray.map(trait => trait.id);
  const categoryIds = traitCategories.map(category => category.id);
  const dispatch = useDispatch();

  const getFilteredTraits = categoryId =>
    selectedTraitsArray.filter(trait => trait.categoryId === categoryId);

  const isValid = categoryIds.every(categoryId =>
    selectedTraitsArray.some(trait => trait.categoryId === categoryId)
  );

  const addTag = (category, trait) => {
    const categoryId = category.id;
    const traitName = trait.name;
    const traitId = trait.id;
    const maxTraitsAllowed = category.max;

    setSelectedTraits(prevSelectedTraits => {
      const tags = prevSelectedTraits[categoryId] || [];
      const currentTraitCount = tags.length;

      if (currentTraitCount < maxTraitsAllowed) {
        const updatedTraits = [...tags, { name: traitName, id: traitId, categoryId }];
        return { ...prevSelectedTraits, [categoryId]: updatedTraits };
      }

      return prevSelectedTraits;
    });
  };

  const removeTag = (traitId, tagId) => {
    setSelectedTraits(prevTags => {
      const updatedTags = prevTags[traitId].filter(tag => tag.id !== tagId);

      if (updatedTags.length === 0) {
        const { [traitId]: _, ...rest } = prevTags;
        return rest;
      }

      return { ...prevTags, [traitId]: updatedTags };
    });
  };

  const handleClose = () => dispatch(closeModal('Inventory Edit'));

  useEffect(() => {
    getRequest({ endpoint })
      .then(categories => setTraitCategories(categories))
      .catch(() => {});
  }, [endpoint]);

  useEffect(() => {
    if (defaultSelectedIds.length === 0) return;

    traitCategories.forEach(category => {
      category.traits.forEach(trait => {
        if (defaultSelectedIds.includes(trait.id)) {
          addTag(category, trait);
        }
      });
    });
  }, [defaultSelectedIds, traitCategories]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h3>YOUR {`${title}`.toUpperCase()}</h3>

        <h2>{subtitle}</h2>
      </div>

      {traitCategories.map(category => (
        <div key={category.id} className={styles.traitsBox}>
          <div className={styles.selectedContainer}>
            {getFilteredTraits(category.id)?.length > 0 && (
              <div className={styles.subTitle}>
                <h4>Selected</h4>

                <h4
                  className={
                    category.max - getFilteredTraits[category.id]?.length === 0
                      ? 'color-red'
                      : 'color-main'
                  }
                >
                  {category.max - selectedTraits[category.id]?.length} left
                </h4>
              </div>
            )}

            {getFilteredTraits(category.id).length > 0 && (
              <div className={styles.selectedTags}>
                {getFilteredTraits(category.id)?.map(tag => (
                  <Chip
                    key={tag.id}
                    label={tag.name}
                    color="secondary"
                    deleteIcon={<CloseIcon />}
                    className={styles.chip}
                    onDelete={() => removeTag(category.id, tag.id)}
                    onClick={() => removeTag(category.id, tag.id)}
                  />
                ))}
              </div>
            )}
          </div>

          <div style={{ padding: '15px 15px 0 15px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ marginRight: 5 }}>{category.name}</h4>{' '}
              <span>(Select max {category.max})</span>
            </div>

            <p className={styles.description}>({category.question})</p>
            <div className={styles.popularTags}>
              {category.traits.map(trait => (
                <Chip
                  key={trait.id}
                  label={trait.name}
                  color="secondary"
                  variant="outlined"
                  className={styles.chip}
                  onClick={() => addTag(category, trait)}
                  disabled={selectedTraitsArray.some(
                    selectedTrait => selectedTrait.id === trait.id
                  )}
                  clickable
                />
              ))}
            </div>
          </div>
        </div>
      ))}

      {!noSteps && (
        <Stepper
          handleStepChange={() => handleStepChange(traitIds)}
          disabled={!isValid || isLoading || disableButton}
          isLoading={isLoading}
          showSteps={showSteps}
          buttonText={showSteps ? 'Next' : 'Done'}
          newStyles={isDialog ? styles.wrapper : {}}
        />
      )}

      {showButtons && (
        <BottomButtons
          text="Skip For Now"
          textContained="Done"
          handleClick={() => handleStepChange(traitIds)}
          handleClose={handleClose}
          newStyles={styles}
          disabled={!isValid || isLoading || disableButton}
          fixed
        />
      )}
    </div>
  );
};

TraitSelection.propTypes = {
  title: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  defaultSelectedIds: PropTypes.array,
  noSteps: PropTypes.bool,
  isLoading: PropTypes.bool,
  disableButton: PropTypes.bool,
  showSteps: PropTypes.bool,
  subtitle: PropTypes.string,
};

TraitSelection.defaultProps = {
  noSteps: false,
  isLoading: false,
  disableButton: false,
  showSteps: true,
  defaultSelectedIds: [],
};

export default TraitSelection;
