import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import { MenuItem, MenuList, Divider, Accordion, AccordionSummary } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { openModal } from 'features/utilsSlice';
import FilterIcon from 'public/icons/filter-main.svg';
import BillingIcon from 'public/icons/billing.svg';
import SettingsIcon from 'public/icons/cog.svg';
import DollarIcon from 'public/icons/dollar-green.svg';
import MessageIcon from 'public/icons/msg-window.svg';
import ChevronRightIcon from 'public/icons/chevron-right.svg';
import HandshakeIcon from 'public/icons/handshake-thick.svg';
import TransitionDialog from 'components/TransitionDialog';
import Settings from './Settings';
import Footer from './Footer';
import AffiliateDialog from 'components/AffiliateDialog';
import TwoFactorSettingsDialog from 'components/sidebar/TwoFactorSettingsDialog';
import InvoicingDetailsDialog from 'components/InvoicingDetailsDialog';
import ExternalLink from 'components/ExternalLink';
import styles from 'styles/components/sidebar/DropdownList.module.scss';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 5,
    width: 18,
    height: 18,
    zIndex: 2,
  },

  label: {
    padding: 0,
  },

  content: {
    margin: 0,
    fontFamily: 'Avenir Heavy',
    fontSize: 16,
  },

  expanded: {
    margin: '0px !important',
    minHeight: '50px !important',
    alignItems: 'center',
  },

  expandIcon: {
    margin: 0,
    padding: 0,
    transform: 'rotate(0deg)',

    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },

  paper: {
    boxShadow: 'none',
    margin: '0 !important',

    '&::before': {
      display: 'none',
    },
  },
});

const InfluencerSidebar = ({ open, handleClose, handleLogout }) => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <TransitionDialog open={open} handleClose={handleClose} styles={styles}>
      <div className={styles.profilesContainer} />

      <div
        className={styles.menuContainer}
        style={currentUser.profiles?.length > 1 ? { paddingTop: 40 } : {}}
      >
        <div className={styles.profileInfo} style={{ marginTop: 40, padding: '0 0 25px 15px' }}>
          <h3>Agency: {currentUser?.name}</h3>
        </div>

        <Divider />

        <div style={{ padding: '20px 0' }}>
          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <FilterIcon /> <h3>My account</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList}>
              <MenuItem
                onClick={() => {
                  dispatch(openModal({ name: 'Settings' }));
                  handleClose;
                }}
              >
                My account info
              </MenuItem>
            </MenuList>
          </Accordion>

          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
                details: classes.details,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <BillingIcon /> <h3>Billing</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList} autoFocusItem={open}>
              <Link href="/wallet" passHref>
                <MenuItem onClick={handleClose}>Wallet</MenuItem>
              </Link>

              <Link href="/payments" passHref>
                <MenuItem onClick={handleClose}>Payment history</MenuItem>
              </Link>

              <MenuItem onClick={() => dispatch(openModal({ name: 'Invoicing Details' }))}>
                Invoicing details
              </MenuItem>
            </MenuList>
          </Accordion>

          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
                details: classes.details,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <SettingsIcon /> <h3>Settings</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList}>
              <MenuItem onClick={() => dispatch(openModal({ name: 'Two Factor Settings' }))}>
                Two factor authentication
              </MenuItem>

              {!currentUser.connected_user && (
                <Link href="/users/new" passHref>
                  <MenuItem onClick={handleClose}>Create an advertiser account</MenuItem>
                </Link>
              )}
            </MenuList>
          </Accordion>

          <div className={styles.refItem}>
            <div className={styles.heading}>
              <MessageIcon />

              <ExternalLink
                preview="url"
                mobileUrl="https://gigsocial.crisp.help/en-us/category/buying-on-gigsocial-7fa8h5/"
                desktopUrl="https://gigsocial.crisp.help/en-us/category/buying-on-gigsocial-7fa8h5/"
                page="FAQ"
                target="_blank"
                rel="noreferrer noopener"
                style={{ color: 'unset' }}
              >
                <h3>FAQ/Chat support</h3>
              </ExternalLink>
            </div>
          </div>

          <div className={styles.refItem}>
            <MenuItem onClick={() => dispatch(openModal({ name: 'Affiliate Program' }))}>
              <div className={styles.heading}>
                <DollarIcon /> <h3>Refer friends</h3>
              </div>
            </MenuItem>
          </div>

          <Link href={`/partners`} passHref>
            <div
              className={styles.heading}
              style={{ minHeight: 50, padding: '0px 16px' }}
              onClick={handleClose}
            >
              <HandshakeIcon /> <h3>Partners</h3>
            </div>
          </Link>
        </div>

        <Divider />

        <Footer handleClose={handleClose} handleLogout={handleLogout} />
      </div>

      <Settings />
      <AffiliateDialog />
      <TwoFactorSettingsDialog />
      <InvoicingDetailsDialog />
    </TransitionDialog>
  );
};

InfluencerSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default InfluencerSidebar;
