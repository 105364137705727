import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import uniq from 'lodash/uniq';

import toast from 'utils/toast.jsx';
import { postRequest } from 'utils/api';
import { closeModal, openModal } from 'features/utilsSlice';
import { formatPrice } from 'utils/helpers';
import { setCart } from 'features/cartSlice';
import IconMainContainer from 'components/IconMainContainer';
import ActionCable from 'components/ActionCable/Cable';
import useModalOpen from 'hooks/useModalOpen';
import useCheckoutSuccess from 'hooks/useCheckoutSuccess';
import TransitionDialog from 'components/TransitionDialog';
import InfoIcon from 'public/icons/info.svg';
import CartItem from './CartItem';
import GuestLogin from './GuestLogin';
import Subtotal from './Subtotal';
import PaymentOptions from './PaymentOptions';
import PaymentDialog from 'components/cart/PaymentDialog';
import InfluencerResponseDialog from 'components/InfluencerResponseDialog';
import LoginDialog from 'components/LoginDialog';
import ExternalLink from 'components/ExternalLink';
import CloseIcon from 'public/icons/close-main.svg';
import styles from 'styles/components/cart/Cart.module.scss';

const labelStyle = { textAlign: 'left', margin: '0px 15px 0px 15px' };

const CartDialog = () => {
  const [paymentOption, setPaymentOption] = useState('card');
  const [discountRules, setDiscountRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [licenseAccepted, setLicenseAccepted] = useState(false);
  const [postTermsAccepted, setPostTermsAccepted] = useState(false);
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const open = useModalOpen('Cart');
  const dispatch = useDispatch();
  const handleCheckoutSuccess = useCheckoutSuccess('wallet');
  const buttonText = isLoggedIn ? 'Checkout' : 'Guest Checkout';

  const handleClose = () => dispatch(closeModal('Cart'));

  const cart = useSelector(state => state.cart);
  const cartItems = cart.cart_items;
  const uniqueNetworksCount = uniq(cartItems?.map(item => item.network.id)).length;

  const nextDiscountRule = discountRules
    .sort((a, b) => a.unique_networks_discount - b.unique_networks_discount)
    .find(rule => rule.unique_networks_condition > uniqueNetworksCount);

  // useEffect(() => {
  //   getRequest({ endpoint: 'discount_rules' }).then(rules => setDiscountRules(rules));
  // }, []);

  useEffect(() => {
    if (!open) return;

    postRequest({ endpoint: `carts/${cart.id}/refresh_start_times`, showErrors: false })
      .then(response => {
        dispatch(setCart(response.data));
      })
      .catch(() => {});
  }, [cart.id, dispatch, open]);

  const handleCheckout = () => {
    if (paymentOption !== 'wallet') {
      dispatch(openModal({ name: 'Payment' }));
      return;
    }

    setLoading(true);

    postRequest({
      endpoint: `carts/${cart.id}/checkout?async=true`,
      data: { payment_method: 'wallet' },
      successMessage: '',
      errorMessage: 'There was an error during the checkout. Please try again or contact support.',
    });
  };

  if (!cartItems || cartItems?.length === 0) return <InfluencerResponseDialog />;

  const hasLicense = cartItems.some(item => item.inventory_space.license);
  const hasAdultPosts =
    !cart.guaranteed_gains &&
    cartItems.some(item => ['OnlyFans', 'LoyalFans'].includes(item.network?.name));
  const buttonDisabled =
    (hasLicense && !licenseAccepted) || (hasAdultPosts && !postTermsAccepted) || loading;

  return (
    <>
      <TransitionDialog
        open={open}
        handleClose={handleClose}
        noCloseIcon
        direction="up"
        styles={styles}
      >
        {paymentOption === 'wallet' && (
          <ActionCable
            channel="CheckoutChannel"
            onReceived={({ type, payload }) => {
              if (type === 'success') {
                console.log('payload', payload);
                handleCheckoutSuccess(payload);
                setLoading(false);
              } else {
                console.log('Checkout error', payload);
                setLoading(false);
                toast(
                  'error',
                  payload?.errors?.[0] ||
                    'There was an error during the checkout. Please try again or contact support.',
                  {
                    icon: (
                      <IconMainContainer
                        src="/icons/notifications/times.svg"
                        alt="Times"
                        errorStyle
                      />
                    ),
                  }
                );
              }
            }}
          />
        )}

        <div className={styles.cart}>
          <div className={styles.header}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            Your Order
          </div>

          <div className={styles.content}>
            <div className={styles.info}>
              {!isLoggedIn && (
                <div className={styles.notLoggedInBox}>
                  <h4>Already have an account?</h4>

                  <Button
                    onClick={() => dispatch(openModal({ name: 'Login' }))}
                    color="primary"
                    variant="contained"
                  >
                    Login
                  </Button>
                </div>
              )}

              <div className={styles.title}>
                <h3>PEACE OF MIND</h3>
                <InfoIcon width={16} height={16} alt="Info" />
              </div>

              <p>
                Your payment will be taken and held by GigSocial, but will not be paid to the
                influencer until each milestone is delivered and approved by you.
              </p>
            </div>

            <CartItem
              handleCloseOrder={handleClose}
              styles={styles}
              cart={cart}
              cartItems={cartItems}
            />

            {/* 
          {nextDiscountRule && (
            <div className={styles.offer}>
              <Button>Offer</Button>

              <p>
                Order {nextDiscountRule.unique_networks_condition - uniqueNetworksCount} more{' '}
                {pluralize(
                  'network',
                  nextDiscountRule.unique_networks_condition - uniqueNetworksCount
                )}{' '}
                to achieve a {nextDiscountRule.unique_networks_discount}% total discount
              </p>
            </div>
          )} */}
          </div>

          <Subtotal cart={cart} styles={styles} />

          <PaymentOptions
            styles={styles}
            paymentOption={paymentOption}
            setPaymentOption={setPaymentOption}
            totalPrice={+cart.total_checkout_price}
          />

          {hasLicense && (
            <FormControlLabel
              control={<Checkbox color="primary" name="license" size="medium" />}
              checked={licenseAccepted}
              onChange={e => setLicenseAccepted(e.target.checked)}
              labelPlacement="end"
              label={
                <>
                  I accept licensing{' '}
                  <ExternalLink
                    preview="url"
                    mobileUrl="/terms"
                    desktopUrl="/terms"
                    target="_blank"
                    rel="noreferrer noopener"
                    page="Licensing Terms"
                  >
                    terms of service
                  </ExternalLink>
                </>
              }
            />
          )}

          {hasAdultPosts && (
            <FormControlLabel
              control={<Checkbox color="primary" name="post_terms" size="medium" />}
              checked={postTermsAccepted}
              onChange={e => setPostTermsAccepted(e.target.checked)}
              labelPlacement="end"
              label="I acknowledge that I am purchasing a post. Although proof screenshots & analytics are provided, there is no guarantee of subscribers or results and this charge can’t be refunded if the seller performs the post"
              style={labelStyle}
            />
          )}

          <div className={styles.content}>
            <div className={styles.info}>
              <div className={styles.title}>
                <h3>PEACE OF MIND</h3>
                <InfoIcon width={16} height={16} alt="Info" />
              </div>

              <p>
                Your payment will be taken and held by GigSocial, but will not be paid to the
                influencer until each milestone is delivered and approved by you.
              </p>
            </div>
          </div>

          <div className={styles.footer}>
            <span className={styles.price}>{formatPrice(cart.total_checkout_price)}</span>

            <Button
              variant="contained"
              color="primary"
              disabled={buttonDisabled}
              onClick={handleCheckout}
            >
              {buttonText} {loading && <CircularProgress size="2rem" style={{ marginLeft: 10 }} />}
            </Button>

            <PaymentDialog paymentOption={paymentOption} />
          </div>
        </div>
      </TransitionDialog>

      <LoginDialog />
    </>
  );
};

export default CartDialog;
